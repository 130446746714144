<template>
  <div class="tiles-wrapper" v-if="subcontractorsList && subcontractorsList.length > 0">
    <DashboardSection>
      <template #top-right-button>
        <CustomButton
          @onClick="$router.push({ name: 'AddSubcontractor' })"
          iconClass="plus"
          label="New subcontractor"
          color="grey"
          size="small"
        />
      </template>
      <template #content>
        <div class="users-row">
          <Tile v-for="item in subcontractorsList" :key="item.id">
            <template #title>
              <router-link
                :to="{ name: 'SubcontractorInformation', params: { id: item.id } }"
                class="subcontractor-tile"
              >
                <span class="heading">
                  {{ item.name }}
                </span>
              </router-link>
            </template>
            <template #desc> {{ item.ProjectService.length }} Projects </template>
            <template #menu>
              <ul>
                <li @click="$router.push({ name: 'EditSubcontractor', params: { id: item.id } })">Edit</li>
                <li @click="deleteSubcontractor(item.id)">Delete</li>
              </ul>
            </template>
          </Tile>
        </div>
      </template>
    </DashboardSection>
  </div>
  <div class="no-documents" v-else>
    <NoResult title="You Haven't added any subcontractors yet">
      <CustomButton @click="$router.push({ name: 'AddSubcontractor' })" label="Add new subcontractor" color="blue" />
    </NoResult>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useStore } from '@/store'
import { ActionTypes as studioActions } from '@/store/studio/actions'
import Tile from '@/components/atom/Tile.vue'
import CustomButton from '@/components/atom/CustomButton.vue'
import DashboardSection from '@/components/atom/DashboardSection.vue'
import NoResult from '@/components/molecules/NoResult.vue'
import authApi from '@/api/Authenticated'

export default defineComponent({
  name: 'Team',
  components: {
    Tile,
    CustomButton,
    DashboardSection,
    NoResult
  },
  setup() {
    const store = useStore()
    store.dispatch(studioActions.GET_SUBCONTRACTORS)
    const subcontractorsList = computed(() => store.state.studio.subContractors)
    async function deleteSubcontractor(id: number) {
      await authApi.deleteSubcontactor(id).then(function () {
        const subIndex = subcontractorsList.value.findIndex((e: any) => e.id === id)
        if (subIndex !== -1) {
          subcontractorsList.value.splice(subIndex, 1)
        }
      })
    }
    return { subcontractorsList, deleteSubcontractor }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass">
.users-row
  display: flex
  flex-wrap: wrap
  & > *
    margin-right: 1rem
    margin-bottom: 1rem
  @media( max-width: $md-breakpoint)
    flex-direction: column
.heading
  font-weight: bold
.subcontractor-tile
  text-decoration: none
</style>
