
import { defineComponent, computed } from 'vue'
import { useStore } from '@/store'
import { ActionTypes as studioActions } from '@/store/studio/actions'
import Tile from '@/components/atom/Tile.vue'
import CustomButton from '@/components/atom/CustomButton.vue'
import DashboardSection from '@/components/atom/DashboardSection.vue'
import NoResult from '@/components/molecules/NoResult.vue'
import authApi from '@/api/Authenticated'

export default defineComponent({
  name: 'Team',
  components: {
    Tile,
    CustomButton,
    DashboardSection,
    NoResult
  },
  setup() {
    const store = useStore()
    store.dispatch(studioActions.GET_SUBCONTRACTORS)
    const subcontractorsList = computed(() => store.state.studio.subContractors)
    async function deleteSubcontractor(id: number) {
      await authApi.deleteSubcontactor(id).then(function () {
        const subIndex = subcontractorsList.value.findIndex((e: any) => e.id === id)
        if (subIndex !== -1) {
          subcontractorsList.value.splice(subIndex, 1)
        }
      })
    }
    return { subcontractorsList, deleteSubcontractor }
  }
})
